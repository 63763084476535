<template>
  <div class="home">
    <img src="@/assets/first-bg.png" alt="" class="first-bg">
  </div>
</template>

<script>
export default {
  name: 'Home',
  mounted() {
   this.goURL()
  },
  methods:{
    goURL(){
      this.$nextTick(()=>{
        setTimeout(() => {
          this.$router.push('/brandintroduce')
        },3000)
      })

    }

  }
}
</script>
<style scoped>
.home{
  width: 100%;
  height: 100vh;
}
.first-bg{
  width: 100%;
  height: 100%;
}
</style>
