import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    //品牌介绍
    path: '/brandintroduce',
    name: 'brandintroduce',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/brandintroduce.vue')
  },
  {
    //品牌历史
    path: '/brandhistroy',
    name: 'brandhistroy',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/brandhistroy.vue')
  },
  {
    //团购业务介绍
    path: '/tuangouintroduce',
    name: 'tuangouintroduce',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/tuangouintroduce.vue')
  },
  {
    //七大模块首页
    path: '/sevenmoduce',
    name: 'sevenmoduce',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/sevenmoduce.vue')
  },
  {
    //模块详情页
    path: '/moduceintroduce',
    name: 'moduceintroduce',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/moduceintroduce.vue')
  },
  {
    //服务的公司
    path: '/customercompany',
    name: 'customercompany',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/customercompany.vue')
  },
  {
    //服务的客户
    path: '/customername',
    name: 'customername',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/customername.vue')
  },
  {
    //服务公益
    path: '/servecustomers',
    name: 'servecustomers',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/servecustomers.vue')
  },
  {
    //信息收集
    path: '/getinformation',
    name: 'getinformation',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/getinformation.vue')
  },
]

const router = new VueRouter({
  routes
})

export default router
